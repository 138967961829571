// ionclude all your related style here in the header, you can leave it empty if the page has no header
html, body { 
  height:100%; 
  min-height:100%; 
  font-family: $NotoSansCJKtc; 
}

header {
  padding: rem(10px 0);
  z-index: 2;

  .container {
    align-items: center;

  }

  .logo {
    padding: rem(12px 0);

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }

  .menu {
    flex-grow: 1;
    font-size: rem(15px);
    font-family: $NotoSansCJKtc;
    text-align: right;
    font-weight: 500;

    li {
      display: inline-block;
      position: relative;
      padding-bottom: rem(29px);
      margin-bottom: rem(-29px);
      cursor: pointer;
      
      & + li {
        margin-left: rem(30px);
      }

      a {
        color: $coBlue;
      }

      &:hover {

        .dropdown {
          display: block;
        }
      }
    }

    .dropdown {
      
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      width: 120px;
      background: $coBlue;
      
      a {

        position: relative;
        display: block;
        padding: rem(10px 15px);
        padding-right: rem(30px);
        color: $coWhite;
        text-align: left;

        &:after {
          font-family: 'Font Awesome 5 Pro';
          font-weight: 900;
          position: absolute;
          top: 50%;
          right: 15px;
          @include transform(translateY(-50%))
          content: '\f0da';
        }

        &:hover {
          background: darken($coBlue, 10%);
        }
      }
    }

    @include media-breakpoint-down(md) {
      display: none !important;
    }

  }

  .header-detail {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
  }
  
}

.header-detail {

  display: flex;
  align-items: center;
  font-size: rem(13px);
  font-weight: 500;
  font-family: $fontDefault;
  color: $coGray200;
  margin-left: rem(20px);
  text-align: right;
  
  &:before {
    display: inline-block;
    content: '|';
    margin-right: rem(20px);
  }

  li {
    display: inline-block;

    & + li {
      margin-left: rem(15px);
    }

    i {
      font-size: rem(16px);
      margin-right: rem(5px);
    }
  }

  @include media-breakpoint-down(lg) {
    flex-basis: 42%;
  }

}