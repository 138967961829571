.modal {

  .modal-xl {
    max-width: 1140px !important;

    .modal-content {
      width: calc(100% - 10px);
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .modal-content {

    border: none;
    border-radius: 0;
  }

  .modal-header {
    border-bottom: none;
    z-index: 9;
  }

  .modal-body {

    padding: rem(10px 40px 40px 40px);

    h2 {
      font-size: rem(26px);
      margin-bottom: 0;
      font-weight: 700;
    }

    h4 {
      font-size: rem(15px);
      font-weight: 700;
    }

    span {
      display: block;
      color: $coBlue;
      font-size: rem(17px);
      margin-bottom: rem(20px);
    }

    .modal-left {
      
      .modal-pic-left {

        margin: rem(- 40px);
        margin-top: rem(40px);
        margin-right: rem(40px);

        &.top-left {
          margin-top: rem(-66px);
          margin-bottom: rem(40px);
        }

        @include media-breakpoint-down(sm) {
          margin-bottom: rem(40px);
          margin-right: 0;
          margin-left: rem(- 20px);
        }
      }

    }

    .modal-right {
      display: flex;
      flex-direction: column;
      height: 100%;

      .modal-pic-right {
        margin-bottom: rem(30px);
      }

      .modal-right-bottom {

        margin-top: auto;
      }
    }

    @include media-breakpoint-down(sm) {

      padding: rem(10px 20px 20px 20px);
    }
  }
}