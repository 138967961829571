.filter {
  display: flex;
  align-items: center;
  color: $coBlue;
  margin-bottom: rem(20px);

  i {
    font-size: rem(25px);
    margin-right: rem(10px);
  }
}