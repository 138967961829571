.box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 319px;
  padding: rem(30px);
  width: 90%;
  background: rgba($coWhite, 0.7);  

  h4 {
    font-size: rem(19px);
  }

  h2 {
    font-size: rem(34px);
    font-weight: 400;
    margin-bottom: 0;
  }

  &.no-plus {
    &:after {
      display: none;
    }
  }

  &.box-lg {
    max-width: 675px;
    min-height: 300px;
    padding: 40px;

    h4 {
      font-size: rem(27px);
    }

    &:after {
      font-size: rem(30px);
    }

    @include media-breakpoint-down(sm) {
      min-height: 1px;
    }
  }

  &.box-md {
    max-width: 410px;
  }

  &:after {
    position: absolute;
    top: rem(7px);
    right: rem(10px);
    font-family: 'Font Awesome 5 Pro';
    font-size: rem(20px);
    content: '\f067';
  }

  .icons {
    margin-right: rem(15px);
  }

  @include media-breakpoint-down(md) {
    padding: rem(25px 15px);
    
    h2 {
      font-size: rem(30px);
    }

  }

  @include media-breakpoint-down(sm) {
    padding: rem(25px 15px);
    
    h2 {
      font-size: rem(20px);
    }

    h4 {
      font-size: rem(13px);
    }

    &:after {
      top: rem(1px);
      right: rem(5px);
    }

  }
}