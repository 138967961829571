.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  height: 55px;
  padding: rem(12px);
  background: $coBlue;
  cursor: pointer;
  z-index: 5;

  span {
    display: block;
    border-top: 2px solid $coWhite;
    @include transition(all 0.2s ease);

    &:nth-child(2) {
      margin: rem(6px 0);
      width: 75%;
    }

    &:last-child {
      width: 50%;
    }
  }

  &.close {
    opacity: 1;
    
    span {
      display: block;
      border-top: 2px solid $coWhite;
  
      &:nth-child(2) {
        display: none;
      }

      &:first-child {
        @include transform(rotate(45deg));
        margin-bottom: rem(-1px);
      }
  
      &:last-child {
        width: 100%;
        @include transform(rotate(-45deg));
        margin-top: rem(-1px);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: rem(20px 0);
  overflow: auto;
  background: $coWhite;
  z-index: 3;
  @include transform(translateX(100%));

  &.easing {
    @include transition(all 0.3s ease);
  }

  &.active {
    @include transform(translateX(0));
  }

  .logo {

    margin-bottom: rem(40px);
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    text-align: center;
    justify-content: center;

    .mobile-navlink {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: rem(20px);
      padding-left: 0;
      font-size: rem(18px);

      li {
        margin-bottom: rem(30px);

        a {
          color: $coBlue;
          font-weight: 700;
        }
      }

      .sub-menu {
        display: flex;
        flex-direction: column;
        margin-top: rem(10px);

        a {
          font-weight: 500;
          margin-top: rem(10px);
          color: #35579a;
        }
      }

    }
  }

  .header-detail {
    flex-basis: auto;
    margin-left: 0;
    text-align: center;
    justify-content: center;

    &::before {
      display: none;
    }

    li {
      display: block;

      & + li {
        margin-top: rem(20px);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}