.carousel {

  .carousel-indicators {
    
    margin: auto;
    top: rem(50px);
    left: auto;
    right: rem(50px);
    bottom: auto;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      text-indent: 0;
      width: 30px;
      height: 30px;
      color: $coBlue;
      border: 1px solid $coBlue;
      background: none;
      opacity: 1;

      &.active {
        background: $coBlue;
        color: $coWhite;
      }
    }

    @include media-breakpoint-down(sm) {
      right: rem(30px);
      bottom: rem(30px);
    }
  }
}