// ionclude all your related style here in the footer, you can leave it empty if the page has no footer
footer { z-index: 2; position: relative; 
  
  .footer-top {
    font-size: rem(15px);
    padding: rem(50px 0);
    background: $coBlue;
    color: $coWhite;

    h4{
      font-size: rem(18px);
    }

  }

  .btn-outline-primary {
    border-color: $coWhite;
    color: $coWhite;
  }

  .footer-grid {

    .grid {
      
      @include media-breakpoint-down(lg) {
        justify-content: center;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
  }

  .footer-details {

    li {
      display: flex;

      span {
        flex-grow: 1;

        &:first-child {
          flex: 0 0 134px;
          flex-basis: auto;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      margin-top: rem(30px);
    }
  }

  .footer-link {
    margin-top: rem(20px);

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        flex-basis: 50%;

        a {
          color: $coWhite;

          &:hover {
            color: $defaultLinkColor;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .footer-nav {
    padding-left: rem(40px);

    &:last-child {
      margin-left: rem(40px);
      border-left: 1px solid $coWhite;
    }
    
    li {

      margin-top: rem(24px);
    }

    @include media-breakpoint-down(lg) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      flex-grow: 1;

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          flex-basis: calc(50% - 30px);
          margin-right: rem(30px);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;

      ul {
        flex-direction: column;

        li {
          flex-basis: 100%;
          margin-right: 0;
        }
      }
    }

  }
  
  .footer-bottom {
    font-size: rem(16px);
    background: $coBlack;
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    justify-content: space-between;
    align-items: center;
    color: $coWhite;

    .container {

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .footer-sns {
  
    a {
      font-size: rem(30px);
      margin-left: rem(20px);
      color: $coWhite;

      &:hover {
        color: $defaultLinkColor;
      }
    }
  }
}

