.stack-grid {

  padding: 0;
	margin: 0;
	margin-left: -#{$stack-gutter-width};
	list-style: none;
	flex-wrap: wrap;
	display: flex;

  .item {
		
		flex-basis: calc(33.33% - #{$stack-gutter-width});
		margin-top: $stack-gutter-width;
		margin-left: #{$stack-gutter-width};
		flex-shrink: 0;
		flex-grow: 0;
	}

	&[data-items="2"] {

		.item {

			flex-basis: calc(50% - #{$stack-gutter-width});
		}
	}
	&[data-items="4"] {
		
		.item {
			
			flex-basis: calc(25% - #{$stack-gutter-width});
		}
	}
	&[data-items="5"] {
		
		.item {
			
			flex-basis: calc(20% - #{$stack-gutter-width});
		}
	}
	&[data-items="6"] {
		
		.item {
			
			flex-basis: calc(16.66% - #{$stack-gutter-width});
		}	
	}
}