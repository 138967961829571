// include all imported fonts here even google fonts
@charset 'utf-8';
/** font face **/

.hk-nova {
  font-family: 'hk_nova';
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');

@font-face {
  font-family: 'hk_nova';
  src: url('font/hknova-regular-webfont.woff2') format('woff2'),
       url('font/hknova-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'hk_nova';
  src: url('font/hknova-medium-webfont.woff2') format('woff2'),
       url('font/hknova-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'hk_nova';
  src: url('font/hknova-bold-webfont.woff2') format('woff2'),
       url('font/hknova-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'NotoSansCJKtc';
  src: url('font/NotoSansCJKtc-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'NotoSansCJKtc';
  src: url('font/NotoSansCJKtc-Bold-Alphabetic.woff2') format('woff2'),
       url('font/NotoSansCJKtc-Bold-Alphabetic.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}
/** font face **/
