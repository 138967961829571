.services-stock {

  .services {
    display: flex;
    margin-top: rem(30px);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .services-graphic {

    max-width: 480px;
    flex: 0 0 45%;
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      flex: 0 0 auto;
    }
  }

  .services-text {

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: rem(30px);

    h2 {
      color: $defaultColor;
      margin-bottom: rem(15px);
    }

    .services-icons {
      margin-top: auto;
    }

    @include media-breakpoint-down(sm) {
      
      margin-left: 0;
      margin-top: rem(20px);

      .services-icons {
        margin-bottom: rem(20px);
        order: -1;
      }
    }
  }
}