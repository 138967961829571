.figure-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;

  &:hover {
    
    .figure-hover {
      @include transform(translateY(0));    
    }
  }

  &.figure-img-auto {

    @include media-breakpoint-down(sm) {
      height: auto !important;
    }
  }
}

.figure-hover {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;  
  width: 100%;
  height: 100%;
  padding: rem(20px);
  background: rgba($coGray100, 0.8);
  color: $coWhite;
  @include transition(all 0.3s ease);
  @include transform(translateY(-100%));

  p {
    line-height: 1.5;
  }
}