// this is area is for reserved class

html {
  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

#main-container { 
  width: 100%;
  min-height: 100%;
	position: relative;
	flex-direction: column;
  display: flex;
  z-index: 1;

}

main {
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  z-index: 1;
}

.hide { position:relative; }
.ease,a,input[type="submit"],button { @include transition(all 0.5s ease); }
.ease2 { @include transition(all 0.3s ease); }
.loader { position:relative; overflow:hidden; background:#fff url('../images/page_template/loading.gif') no-repeat center; }
.auto { max-width:$maxWidth; margin-left: auto; margin-right: auto; }
.img-auto { width: 100%; height: auto; }
.img-objectfit { width: 100%; height: 100%; object-fit: cover; object-position: center; }

.container {
  max-width: 1183px ;
}

a {
  &:hover {
    text-decoration: none;
    color: $defaultLinkColor;
  }
}

ul, li {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.zx1 { z-index: 1; }
.zx2 { z-index: 2; }
.zx3 { z-index: 3; }
.zx4 { z-index: 4; }
.zx5 { z-index: 5; }
.zx6 { z-index: 6; }
.overflow { overflow: hidden; }
.pl-0 { padding-left: 0; }
.pr-0 { padding-right: 0; }

.preloader { position:fixed; width:100%; height:100%; overflow:hidden; }
.explorer .preloader { background:#fff url('../images/page_template/loading.gif') no-repeat center; }
.explorer .preloader .spinner { display:none; }
// this is area is for reserved class

// you can include here the rest of your class name eg. home page

h1 {

  font-size: rem(41px);

  @include media-breakpoint-down(sm) {
    font-size: rem(35px);
  }
}

h2 {
  font-size: rem(31px);
  margin-bottom: rem(30px);
  line-height: 1.3;
  color: $coBlue;

  @include media-breakpoint-down(sm) {
    font-size: rem(27px);
  }
}

h3 {
  font-size: rem(22px);
  margin-bottom: rem(15px);
}

.cursor-pointer {
  
  cursor: pointer;

}

.overflow-panel {
  
  body {
    overflow: hidden;
  }
}

.btn {
  font-weight: 500;
  border-radius: 0;
  padding-top: rem(10px);
  padding-bottom: rem(10px);
  padding-left: rem(20px);
  padding-right: rem(20px);

  &.btn-wide {
    min-width: 157px;
  }

  &.btn-wide-300 {
    width: 300px;
  }

  &.btn-blue {
    background: $coBlue;
    color: $coWhite;

    &:hover {
      background: lighten($coBlue, 10%);
    }
  }

  i {

    margin-left: rem(15px);
  }
}

.btn-outline-primary {
  border-color: $coBlue;
  color: $coBlue;
}

.btn-arrow-down {
  display: inline-block;
  position: relative;
  font-size: rem(19px);
  padding-bottom: 20px;
  color: $coBlue;

  i {
    position: absolute;
    bottom: 0;
    left: 50%;
    @include transform(translateX(-50%));
  }
}

.section {
  position: relative;
  font-size: rem(15px);

  & + .section {
    margin-top: 70px;
  }

  @include media-breakpoint-down(sm) {
    & + .section {
      margin-top: 50px;
    }
  }

  &.section-highlighted {
    background: $coGray50;

    padding: rem(50px 0);

  }
}

.shadow {
  position: relative;
  padding-top: rem(21px);

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 21px;
    content: '';
    background: url('../images/page_template/shadow.png') no-repeat top center;
  }

  &.shadow-bottom {
    padding-top: 0;

    &:before {
      
      top: auto;
      bottom: 0;
    }
  }
}

.head-title {
  position: relative;
  display: inline-block;
  font-size: rem(27px);
  padding: rem(25px 30px);
  color: $coBlue;
  font-weight: 700;
  margin-bottom: rem(30px);

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 96px;
    background: $coAsh;
    content: '';
    z-index: 0;
  }

  span {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-down(sm) {
    font-size: rem(25px);
    padding: rem(15px 20px);

    &:before {
      width: 60px;
    }
  }
}

.btn-grid {
  display: flex;
  flex-direction: row;

  a {
    &:last-child {
      margin-left: rem(25px);
    }
  }
}

.tag {
  display: flex;
  align-items: center;
  font-size: rem(18px);
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: rem(5px);
  width: 52px;
  height: 52px;
  background: $coBlue;
  color: $coWhite;
  @include transform(translate(0, -20px));
  z-index: 3;
}

.figure-caption {
  position: absolute;
  top: rem(20px);
  left: rem(20px);
  max-width: 46%;

  h3 {
    font-size: rem(27px);
    font-weight: 700;
    color: $coBlack;
  }

  &.outside {
    position: relative;
    top: auto;
    left: auto;
    max-width: 100%;
    
    h3 {
      font-size: rem(22px);
    }
  }
}

.list-disc {
  padding-left: rem(10px);
  margin-left: rem(20px);

  li {
    list-style: disc;
  }
}

.error-msg {
  display: none;
  font-size: rem(13px);
  color: $coRed;
  margin-bottom: 0;

  &.invalid {
    display: block;
  }
}

.custom-control-input {
  width: rem(20px);
  height: rem(20px);
}

.custom-control-label {

  padding-top: rem(3px);
  padding-left: rem(5px);

  &:before,
  &:after {
    width: rem(20px);
    height: rem(20px);
  }
}

.dropdowns {

  font-size: rem(17px);
  margin-top: rem(30px);

  span {
    cursor: pointer;

    &:before {
      display: inline-block;
      margin-right: rem(8px);
      font-family: 'Font Awesome 5 Pro';
      content: '\f068';
    }

    &:hover,
    &.active {
      color: $coBlue;
    }

    &.active {
      
      &:before {
        content: '\f067';
      }
    }
  }

  li {

    margin-top: rem(20px);
  }

  ul {
    display: none;
    margin-left: rem(30px);

    a {
      color: $defaultColor;

      &:hover {
        color: $coBlue;
      }
    }
  }

}
