.hero-banner {
  min-height: 350px;

  img {
    min-height: 350px;
  }

  .hero-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;

    .hero-caption {
      margin-bottom: rem(40px);
      color: $coBlue;

      h3 {
        font-size: rem(23px);
        line-height: normal;
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {

    height: 350px;

  }
}

.section-aboutus {
  
  h2 {
    color: $coGray;
  }
}

.section-service {

  .box {
    padding: rem(25px);

    h2 {
      font-size: rem(28px);
    }
  }

  .figure-img {

    &:hover {

      .figure-caption-hover {
        @include transform(translateY(0));
      }
    }
  }

  .figure-caption-hover {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: rem(20px);
    background: rgba(0,0,0,0.5);
    color: $coWhite;
    line-height: initial;
    @include transform(translateY(-100%));
    @include transition(all 0.3s ease);    
  }

  @include media-breakpoint-down(lg) {
    .box {
  
      h2 {
        font-size: rem(20px);
      }

      .icons {
        width: 40px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

  }

  @include media-breakpoint-down(sm) {
    .section-thumb {
      margin-top: rem(30px) !important;

      .figure-img {
        height: 100%;
      }
    }
  }

}

.section-featured {

  .featured-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.main-product-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding-top: rem(50px);
}

.product-slider {

  @include media-breakpoint-down(lg) {
    height: 400px;
    
  }


  @include media-breakpoint-down(sm) {
    height: 350px;
    
  }

  @include media-breakpoint-down(xs) {
    height: 300px;
  }

  .pro-slider-caption {

    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;

    p {
      line-height: initial;
    }

    @include media-breakpoint-down(md) {
      top: 90px;
      
    }
  }
}

.section-contactus {

  h2 {
    font-size: rem(27px);
  }

  .box {
    top: 0;
    bottom: auto;
  }

  @include media-breakpoint-down(lg) {
    height: 400px;

    .box {
      padding: rem(30px);
      max-width: 60%;
    }
  }

  @include media-breakpoint-down(md) {
    height: 350px;

    .box {
      width: 100%;
      max-width: 500px;

      .head-title {
        font-size: rem(25px);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .head-title {
      font-size: rem(25px);
    }
  }
}