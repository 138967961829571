
.sticky-layout {
  position: sticky;
  top: 50px;
  margin-right: 30px;
  margin-left: auto;
  margin-bottom: -66px;
  width: 35px;
  z-index: 6;

  .sticky {
    position: relative;
    font-size: rem(20px);
    font-weight: bold;
    color: $coBlue;
    padding-left: 8px;
  
    span {
      display: block;
      line-height: 22px;
    }
  
    &:before {
      position: absolute;
      top: 15px;
      left: 0;
      display: inline-block;
      width: 2px;
      height: 73px;
      content: '';
      background: url('../images/page_template/gradient_line.jpg') no-repeat;
    }
  }
}