.breadcrumbs {

  display: flex;
  margin-top: rem(20px);

  a {
    position: relative;
    margin-right: rem(10px);
    color: $coGray;

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }

    &:after {
      margin-left: rem(10px);
      content: '/';
    }

    &.active,
    &:hover {
      color: $coBlue;
    }
  }
}